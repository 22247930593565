const articles = [
    {
        id: 1,
        title: '大井川上流の青い川',
        imageUrl: 'https://media.istockphoto.com/photos/snowy-peaks-of-the-caucasus-mountains-picture-id1327243304?b=1&k=20&m=1327243304&s=170667a&w=0&h=eVHzfEbMv7ki-LY0p-KO4L7X19KaqMYe2GmfPwcrGjU=',
        content: {
            id: 1,
            title: '環境資源名称',
            categories: ['大分類', '中分類', '小分類']
        },
        updatedAt: '2022-01-22T08:52:29.596+09:00',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 2,
        title: '大井川上流の青い川',
        imageUrl: 'https://images.unsplash.com/photo-1610944301914-fe136192aeb8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8dGh1bmRlciUyMGJpcmRzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60',
        content: {
            id: 1,
            title: '環境資源名称',
            categories: ['大分類', '中分類', '小分類']
        },
        updatedAt: '2022-01-22T08:52:29.596+09:00',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 3,
        title: '大井川上流の青い川',
        imageUrl: 'https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fG5hdHVyZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
        content: {
            id: 1,
            title: '環境資源名称',
            categories: ['大分類', '中分類', '小分類']
        },
        updatedAt: '2022-01-22T08:52:29.596+09:00',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 4,
        title: '大井川上流の青い川大井川上流の青い川大井川上流の青い川大井川上流の青い川大井川上流の青い川',
        imageUrl: 'https://images.unsplash.com/photo-1475924156734-496f6cac6ec1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fG5hdHVyZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
        content: {
            id: 1,
            title: '環境資源名称',
            categories: ['大分類', '中分類', '小分類']
        },
        updatedAt: '2022-01-22T08:52:29.596+09:00',
        caption: 'キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 5,
        title: '大井川上流の青い川',
        imageUrl: 'https://images.unsplash.com/photo-1510784722466-f2aa9c52fff6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fG5hdHVyZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
        content: {
            id: 1,
            title: '環境資源名称',
            categories: ['大分類', '中分類', '小分類']
        },
        updatedAt: '2022-01-22T08:52:29.596+09:00',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 6,
        title: '大井川上流の青い川',
        imageUrl: 'https://images.unsplash.com/photo-1446329813274-7c9036bd9a1f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fG5hdHVyZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
        content: {
            id: 1,
            title: '環境資源名称',
            categories: ['大分類', '中分類', '小分類']
        },
        updatedAt: '2022-01-22T08:52:29.596+09:00',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },

]
export default articles
