import Vue from 'vue'
import contentSelector from "../../components/public/common/search-content";

document.addEventListener('DOMContentLoaded', () => {
	const dataElement = document.getElementById('search-content-data')

	const el = document.getElementById('search-content')
	const params = JSON.parse(el.dataset.params || 'null')
	const limit = parseInt(el.dataset.limit ) || 0
	const page = parseInt(el.dataset.page) || 1
	const selectedCategory = [
		dataElement.dataset["category-1Id"].length > 0 ? dataElement.dataset["category-1Id"] : null ,
		dataElement.dataset["category-2Id"].length > 0 ? dataElement.dataset["category-2Id"] : null ,
		dataElement.dataset["category-3Id"].length > 0 ? dataElement.dataset["category-3Id"] : null ,
	]
	const searchWord = dataElement.dataset.searchWord
	const searchType = dataElement.dataset.searchType
	const userId = parseInt(dataElement.dataset.userId)
	const currentUserId = parseInt(dataElement.dataset.currentUserId)
	const currentUserRole = parseInt(dataElement.dataset.currentUserRole)
	const instagramTag = dataElement.dataset.instagramTag
	const app = new Vue({
		render: h => h(contentSelector, {
			props: {
				selectedCategory: selectedCategory,
				searchWord: searchWord,
				searchType: searchType,
				searchParams: params,
				userId: userId,
				currentUserRole: currentUserRole,
				currentUserId: currentUserId,
				limit: limit,
				instagramTag: instagramTag,
				page: page
			}})
	}).$mount();
	el.appendChild(app.$el)
});
