<template>
  <div class="article-element">
    <div class="article-flex">
      <div class="photo-container">
        <div class="photo-wrap">
          <a :href="`/articles/${article.id}`">
            <div class="photo">
              <img :src="article.imageUrl" :alt="article.title"/>
            </div>
          </a>
        </div>
      </div>
      <div class="body">
        <img :src="bgArticle" alt="bgArticle" class="bg-image"/>
        <div class="title"><a :href="`/articles/${article.id}`">{{ article.title }}</a></div>

        <div class="content">
          <div class="date">
            <i class="far fa-calendar-alt"></i> {{date}} <span> > </span><span class="category"><a :href="`/articles?category_1=${article.content.categories[0].id}`">{{article.content.categories[0].title}}</a></span>
          </div>

          <a :href="`/contents/${article.content.id}`"><span class="title">{{article.content.title}}</span></a>

          <div class="caption">
            <p>{{ article.caption }}</p>
          </div>

        </div>
      </div>
    </div>
    <div class="tags">
      <tag v-for="tag in article.tags" :name="tag" :key="tag"/>
    </div>
  </div>
</template>

<script>
import tag from '../common/tag'
import dateUtil from '../../../js/date-util'
import bgArticle from '../../../img/bg-article.png'

export default {
  name: "article-element",
  props: ['article'],
  components: { tag },
  computed: {
    date() {
      return dateUtil.formatDate(new Date(this.article.updatedAt),'YYYY.MM.DD')
    }
  },
  data: () => {
    return {
      bgArticle: bgArticle
    }
  }
}
</script>

<style scoped>

</style>