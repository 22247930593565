<template>
  <div>
    <div class="content-container">
      <content-element v-for="content in contents" :content="content" :key="content.id"></content-element>
    </div>
  </div>
</template>

<script>
import contentElement from './content-element'
import contents from '../../../js/public/data/contents'
import axios from "../../../js/axios-extend";

export default {
  name: "content-list",
  props: [ 'searchParams', 'page', 'limit'],
  components: { contentElement },
  mounted() {
    this.currentPage = this.page
    if (this.searchParams) {
      this.getData();
    }
    else {
      this.contents = contents
    }
  },
  data: () => {
    return {
      contents: [],
      currentPage: 1,
    }
  },
  methods: {
    getData(options={}) {
      let params = this.searchParams
      if (this.limit > 0) {
        params.limit = this.limit;
      }
      this.currentPage = options.page || this.currentPage
      params.page = this.currentPage
      params.perPage = 24
      params.order = 'id'
      params.is_public = 1
      for(let key in params) {
        if(! params[key] || params[key] === ""){
          delete params[key]
        }
      }

      axios.get('/contents.json', { params: params } ).then(response => {
        if( response.data.status === 200 ) {
          this.contents = response.data.contents
          this.$emit('pageChange', response.data.page, response.data.pageCount)
        }
      });
    }
  }
}
</script>

<style scoped>

</style>