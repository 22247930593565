<template>
  <div class="content-element">
    <div class="photo-wrap">
      <div class="photo">
        <a :href="`/contents/${content.id}`"><img :src="content.thumbnailUrl" :alt="content.title"/></a>
      </div>
    </div>
    <div class="body">
      <div class="title"><a :href="`/contents/${content.id}`">{{ content.title }}</a></div>

      <div class="caption"><p>{{  content.summary.replace(/<[^>]*>?/gm, '') }}</p></div>

      <div>
        <span v-if="content.categories[2]" class="category"><span> > </span>
          <a :href="`/contents?category_3=${content.categories[2].id}`">{{content.categories[2].title}}</a>
        </span>
        <span v-else-if="content.categories[1]" class="category"><span> > </span>
          <a :href="`/contents?category_2=${content.categories[1].id}`">{{content.categories[1].title}}</a>
        </span>
        <span v-else class="category">
          <a :href="`/contents?category_1=${content.categories[0].id}`">{{content.categories[0].title}}</a>
        </span>
      </div>

      <div class="tags">
        <tag v-for="tag in content.tags" :name="tag" :key="tag"/>
      </div>
    </div>
  </div>
</template>

<script>
import tag from '../common/tag'

export default {
  name: "content-element",
  components: { tag },
  props: ['content'],
  data: () => {
    return {

    }
  }
}
</script>

<style scoped>

</style>