<template>
  <div>
    <div class="article-container">
      <article-element v-for="article in articles" :article="article" :key="article.id"></article-element>
    </div>
  </div>
</template>

<script>
import articleElement from "./article-element";
import articles from "../../../js/public/data/articles"
import axios from "../../../js/axios-extend";

export default {
  name: "article-list",
  props: [ 'searchParams', 'page', 'limit'],
  components: { articleElement },
  data: () => {
    return  {
      articles: [],
      currentIndex: 0,
      currentPage: 1
    }
  },
  mounted() {
    if (this.searchParams) {
      this.getData();
    }
    else {
      this.movies = articles
    }
    this.currentPage = this.page
  },
  methods: {

    getData(option = {}) {
      const params = this.searchParams
      if (this.limit > 0) {
        params.limit = this.limit
      }
      if (this.searchParams.exclude_id) {
        params.exclude_id = this.searchParams.exclude_id
      }
      params.page = option.page || this.currentPage
      axios.get('/articles.json', { params: params } ).then(response => {
        if( response.data.status === 200 ) {
          this.articles = response.data.articles
          this.$emit('pageChange', response.data.page, response.data.pageCount)
        }
      });
    }
  }
}
</script>
