<template>
  <div>
    <div v-show="!userId">
      <div class="row">
        <div :class="columnClass">
          <div class="form-group keyword">
            <div class="inline">
              <input type="text" class="form-control" placeholder="気になるワードを検索してみよう" v-model="keyword"
                     v-on:keypress.prevent.enter.exact="enableSubmit"
                     v-on:keyup.prevent.enter.exact="search" />
              <button v-on:click="onSearch"><i class="fas fa-search"></i></button>
            </div>
          </div>
        </div>
      </div>

      <h2 class="category">カテゴリー</h2>
      <div class="row">
        <div :class="columnClass">
          <div class="row">
            <div class="col-sm-4">
              <div class="select-wrap">
                <select class="form-control" v-model="category1Id" name="category_1_id" ref="category1" id="category_1_id">
                  <option :value="null">大分類</option>
                  <option v-for="category in this.categories" :value="category.id">{{category.title}}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="select-wrap">
                <select  class="form-control" v-model="category2Id" name="category_2_id" ref="category2" id="category_2_id">
                  <option :value="null">中分類</option>
                  <option v-for="category in this.categories2" :value="category.id">{{category.title}}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="select-wrap">

                <select  class="form-control" v-model="category3Id" name="category_3_id" ref="category3" id="category_3_id">
                  <option :value="null">小分類</option>
                  <option v-for="category in this.categories3" :value="category.id">{{category.title}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="search-button-container">
        <button class="btn btn-primary" v-on:click="onSearch"><i class="fas fa-search"></i>検索</button>
      </div>

      <searchTags :tagColumnClass="tagColumnClass" :search-type="searchType"></searchTags>
    </div>

    <div v-if="dataLoaded">
      <photo-list v-if="searchType === 'photos'"
                  ref="photoList"
                  class="photo-list search-result"
                  :search-params="targetSearchParams"
                  :page="page"
                  :limit="limit"
                  :user-id="userId"
                  :instagram-tag="instagramTag"
                  :current-user-id="currentUserId"
                  :current-user-role="currentUserRole"
                  v-on:pageChange="pageChange"/>

      <movie-list v-if="searchType === 'movies'"
                  ref="movieList"
                  class="movie-list search-result"
                  :search-params="targetSearchParams"
                  :page="page"
                  :limit="limit"
                  v-on:pageChange="pageChange"/>

      <article-list v-if="searchType === 'articles'"
                    ref="articleList"
                    class="article-list search-result"
                    :search-params="targetSearchParams"
                    :page="page" :limit="limit"
                    v-on:pageChange="pageChange"/>

      <content-list v-if="searchType === 'contents' && dataLoaded"
                    ref="contentList"
                    class="content-list search-result"
                    :search-params="targetSearchParams"
                    :page="page"
                    :limit="limit"
                    v-on:pageChange="pageChange"/>

      <div class="pagination-container" v-if="pageCount > 1 && ! isTopPage">
        <pagination
            :page-count="pageCount"
            :prev-text="'<'"
            :next-text="'>'"
            :click-handler="searchData"
            :container-class="'pagination justify-content-center'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            :first-last-button="true"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
            :value="targetPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../../js/axios-extend";
import photoList from '../photo/photo-list'
import articleList from '../article/article-list'
import movieList from '../movie/movie-list'
import contentList from '../content/content-list'
import pagination from 'vuejs-paginate'
import smoothScroll from 'smooth-scroll'
import searchTags from './search-tags'

export default {
  name: "search-content",
  props: [ 'selectedCategory', 'searchWord', 'searchType', 'searchParams', 'page', 'limit', 'userId', 'currentUserId', 'currentUserRole', 'instagramTag' ],
  components: { photoList, articleList, movieList, contentList, pagination, searchTags },
  data: () => {
    return {
      categories: [],
      category1Id: null,
      category2Id: null,
      category3Id: null,
      categories2: [],
      categories3: [],
      dataLoaded: false,
      scope: 0,
      tags: ["絶景", "南アルプスを守る話",'寸又峡','ああああああ'],
      pageCount: 10,
      targetPage: 1,
      keyword: '',
      canSubmitSearch: false,
      targetSearchParams: {}
    }
  },
  async mounted() {
    const response = await axios('/master/categories.json?scope=' + this.scope)
    this.categories = response.data.categories
    this.targetSearchParams = this.searchParams || {}

    this.category1Id = this.selectedCategory[0]
    this.category2Id = this.selectedCategory[1]
    this.category3Id = this.selectedCategory[2]

    this.keyword = this.searchWord

    this.targetSearchParams.category1 = this.category1Id
    this.targetSearchParams.category2 = this.category2Id
    this.targetSearchParams.category3 = this.category3Id
    this.targetSearchParams.keyword = this.searchWord
    this.targetSearchParams.page = this.page

    this.targetPage = this.page

    document.addEventListener('scrollStop', this.getData, false);

    this.$nextTick(() => {
      this.setCategory2()
      this.$nextTick(() => {
        this.setCategory3()
        this.dataLoaded = true
      })
    })
  },
  computed:  {
    isTopPage() {
      return !this.searchType
    },
    columnClass () {
      if(this.isTopPage) {
        return 'col-sm-12'
      }
      else {
        return 'col-sm-8 offset-sm-2'
      }
    },
    tagColumnClass() {
      if(this.isTopPage) {
        return 'col-sm-12'
      }
      else {
        return 'col-sm-10 offset-sm-1'
      }
    },
    targetComponent() {
      switch (this.searchType) {
        case 'photos':
          return this.$refs.photoList
        case 'articles':
          return this.$refs.articleList
        case 'contents':
          return this.$refs.contentList
        case 'movies':
          return this.$refs.movieList
        default:
          return null
      }
    },
    searchGetParams() {
      let params = []
      if (this.category1Id) {
        params.push( 'category_1=' + this.category1Id)
      }
      if (this.category2Id) {
        params.push( 'category_2=' + this.category2Id)
      }
      if (this.category3Id) {
        params.push( 'category_3=' + this.category3Id)
      }
      if (this.keyword) {
        params.push( 'keyword=' + this.keyword)
      }
      return params.join('&')
    }
  },
  methods: {
    onSearch() {
      this.canSubmitSearch = true
      this.search()
    },
    enableSubmit() {
      this.canSubmitSearch = true
    },
    search() {
      if (!this.canSubmitSearch) return;
      this.targetSearchParams.page = 1
      this.targetSearchParams.keyword = this.keyword
      this.targetSearchParams.category1 = this.category1Id
      this.targetSearchParams.category2 = this.category2Id
      this.targetSearchParams.category3 = this.category3Id
      this.getData()
      this.canSubmitSearch = false;
    },
    getData() {
      this.targetSearchParams.page = this.targetPage
      if (this.targetComponent) {
        this.targetComponent.getData(
            this.targetSearchParams
        )
      }
      else {
        location.href = '/contents?' + this.searchGetParams
      }
    },
    searchData(page) {
      this.targetPage = page
      if( this.targetComponent ) {
        const scroll = new smoothScroll()
        const anchor = document.querySelector('.app-body')
        scroll.animateScroll(anchor, null, {
          speed: 10,
          speedAsDuration: true,
          durationMin: 0,
          durationMax: 0
        })
      }
    },
    setCategory2() {
      const category = this.categories[this.$refs.category1.selectedIndex - 1]
      if (category && category.children) {
        this.categories2 = category.children
      }
      else {
        this.categories2 = []
        this.category2Id = null
      }
    },
    setCategory3() {
      const category = this.categories2[this.$refs.category2.selectedIndex - 1]
      if (category && category.children) {
        this.categories3 = category.children
      }
      else {
        this.categories3 = []
      }
    },
    pageChange(page, pages) {
      this.currentPage = page
      this.pageCount = pages
    }
  },
  watch: {
    category1Id() {
      if (!this.dataLoaded) return
      this.setCategory2()
      this.category2Id = null
      this.category3Id = null
    },
    category2Id() {
      if (!this.dataLoaded) return
      this.setCategory3()
      this.category3Id = null
    },
    category3Id() {

    },
  }
}
</script>
