const contents = [
    {
        id: 1,
        title: '南アルプスを望む南アルプスを望む南アルプスを望む南アルプスを望む',
        thumbnailUrl: 'https://media.istockphoto.com/photos/snowy-peaks-of-the-caucasus-mountains-picture-id1327243304?b=1&k=20&m=1327243304&s=170667a&w=0&h=eVHzfEbMv7ki-LY0p-KO4L7X19KaqMYe2GmfPwcrGjU=',
        categories: [{title:'大分類',id: 1}, {title:'中分類',id: 2}, {title:'小分類',id: 3}],
        description: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 2,
        title: '南アルプスを望む南アルプスを望む南アルプスを望む南アルプスを望む',
        thumbnailUrl: 'https://media.istockphoto.com/photos/snowy-peaks-of-the-caucasus-mountains-picture-id1327243304?b=1&k=20&m=1327243304&s=170667a&w=0&h=eVHzfEbMv7ki-LY0p-KO4L7X19KaqMYe2GmfPwcrGjU=',
        categories: [{title:'大分類',id: 1}, {title:'中分類',id: 2}, {title:'小分類',id: 3}],
        description: 'キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 3,
        title: '南アルプスを望む南アルプスを望む南アルプスを望む南アルプスを望む',
        thumbnailUrl: 'https://media.istockphoto.com/photos/snowy-peaks-of-the-caucasus-mountains-picture-id1327243304?b=1&k=20&m=1327243304&s=170667a&w=0&h=eVHzfEbMv7ki-LY0p-KO4L7X19KaqMYe2GmfPwcrGjU=',
        categories: [{title:'大分類',id: 1}, {title:'中分類',id: 2}, {title:'小分類',id: 3}],
        description: 'キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 4,
        title: '南アルプスを望む南アルプスを望む南アルプスを望む南アルプスを望む',
        thumbnailUrl: 'https://media.istockphoto.com/photos/snowy-peaks-of-the-caucasus-mountains-picture-id1327243304?b=1&k=20&m=1327243304&s=170667a&w=0&h=eVHzfEbMv7ki-LY0p-KO4L7X19KaqMYe2GmfPwcrGjU=',
        categories: [{title:'大分類',id: 1}, {title:'中分類',id: 2}, {title:'小分類',id: 3}],
        description: 'キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
    {
        id: 5,
        title: '南アルプスを望む南アルプスを望む南アルプスを望む南アルプスを望む',
        thumbnailUrl: 'https://media.istockphoto.com/photos/snowy-peaks-of-the-caucasus-mountains-picture-id1327243304?b=1&k=20&m=1327243304&s=170667a&w=0&h=eVHzfEbMv7ki-LY0p-KO4L7X19KaqMYe2GmfPwcrGjU=',
        categories: [{title:'大分類',id: 1}, {title:'中分類',id: 2}, {title:'小分類',id: 3}],
        description: 'キャプションが入ります。',
        tags: ['絶景','アルプス','野鳥','あああ','っっっｂ']
    },
]
export default contents;
