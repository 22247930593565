<template>
  <div class="recommend-words">
    <div class="label">
      よく検索されているワード
    </div>

    <div class="row">
      <div :class="tagColumnClass">
        <div class="tags">
          <span class="tag" v-for="tag in tags"><a :href="`/${searchType || 'contents'}?keyword=${tag.word}`">{{tag.word}}</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../js/axios-extend'

export default {
  name: "search-tags",
  props: ['tagColumnClass', 'searchType'],
  data: () => {
    return {
      tags: []
    }
  },
  mounted() {
    this.getTags()
  },
  methods: {
    getTags() {
      axios.get(`/search_tags/${this.searchType || 'contents'}.json`, {params: { limit: 8 }}).then(response => {
        this.tags = response.data.tags
      })
    }
  }
}
</script>